/* Promo Slider */
!function(w, d) {

  function qsa(q, c) {
    return Array.prototype.slice.call((c||d).querySelectorAll(q))
  }

  qsa('.slider2').map(function(el) { return new Slider(el) })
  
  function Slider(el) {
    
    //duplicate items
    qsa('.items > *', el).forEach(function(item) { item.parentNode.appendChild(item.cloneNode(true)) })

    var slides = qsa('.items > *', el).map(function(item) { return new Slide(item) }),
        count = slides.length,
        last = count - 1,
        prevBtn = el.querySelector('.prev'),
        nextBtn = el.querySelector('.next'),
        navBtns = el.querySelector('.bulvars'),
        btns = qsa('.bulvar', navBtns),
        speed = el.getAttribute('data-speed') || 4000,
        play = el.classList.contains('play'),
        clicklimit = el.classList.contains('clicklimit'),
        cur = 0,
        _prev = last-1,
        _next = 0,
        timeout,
        an

    goTo(cur)
    
    if(count > 1)
    {
      on(prevBtn, 'click', prev)
      on(nextBtn, 'click', next)
      on(navBtns, 'click', nav)
      on(w, 'load', function(e) { el.classList.add('loaded') })
                
      var start = null
      var end = null
      
      on(el, 'mousedown touchstart', function(e) {
        // console.log('mousedown',e)
        start = e.touches ? e.changedTouches[0] : e
      })
      
      on(el, 'mouseup touchend', function(e) {
        // console.log('mouseup',e)
        end = e.touches ? e.changedTouches[0] : e
        var y = start.screenY - end.screenY
        var x = start.screenX - end.screenX
        if(Math.abs(y) > Math.abs(x)) return
        if (x > 0) next()
        if (x < 0) prev()
      })
    }

    function goTo(i, rev) {
      if(clicklimit && slides[cur].isAn()) return
      // if(!slides[i]) return
      cur = i
      var half = Math.floor(count/2)
      slides.forEach(function(slide, i) {
        var j = i - cur
        if(j > half) j -= count
        if(j < -half) j += count
        slide.el.style.setProperty('--i', j)
        slide.el.classList.remove('current')
        slide.el.classList.remove('next')
        slide.el.classList.remove('prev')
        if(j == 0) slide.el.classList.add('current')
        if(j == 1) slide.el.classList.add('next')
        if(j == -1) slide.el.classList.add('prev')
      })
      // slides.forEach(function(slide) { slide.reset() })
      // slides[_prev].el.classList.add('prev')
      // slides[cur].set(true, rev)
      // slides[_next].set(false, rev)
      // if(slides[i+1]) slides[i+1].el.classList.add('next')
      // else if(slides[0]) slides[0].el.classList.add('next')
      updateBtns(cur, i)
      // _prev = cur
      // cur = i
      // _next = i == count ? 0 : i+1
      que()
      if(an) el.classList.add('an')
      an = true
    }
    
    function prev() {
      goTo(cur > 0 ? cur - 1 : last, 1)
    }
    
    function next() {
      goTo(cur < last ? cur + 1 : 0)
    }
    
    function nav(e) {
      if(e.target != this) {
        var i = index(e.target)
        goTo(i, i < cur)
      }
    }

    function updateBtns(cur, next) {
      if(btns[cur]) btns[cur].classList.remove('active')
      if(btns[next]) btns[next].classList.add('active')
    }
    
    function que() {
      if(timeout) clearTimeout(timeout)
      if(play && count > 1) timeout = setTimeout(next, speed)
    }
  }

  function Slide(el) {
    var an, io, fb

    this.el = el
    
    this.set = function(isOut, isReverse) {
      // this.reset()
      el.classList.add('an')
      el.classList.add(io = isOut ? 'out':'in')
      el.classList.add(fb = isReverse ? 'backward':'forward')
      return this
    }
    
    this.reset = function() {
      el.classList.remove('an')
      el.classList.remove('prev')
      el.classList.remove('next')
      el.classList.remove('in')
      el.classList.remove('out')
      el.classList.remove('forward')
      el.classList.remove('reverse')
      // if(io) el.classList.remove(io)
      // if(fb) el.classList.remove(fb)
      io = fb = null
      return this
    }
    
    this.isAn = function() {
      return an
    }
    
    // on(el, 'animationstart', function(){ an = true })
    // on(el, 'animationend', function(){ an = false })
    on(el, 'transitionstart', function(e){ an = true })
    on(el, 'transitionend', function(e){ an = false })
    
  }
  
  function on(el, ev, fn) {
    ev.split(' ').forEach(function(e) {
      if(el) el.addEventListener(e, fn, false);
    })
  }
  function index(node) {
    return [].indexOf.call(node.parentNode.children, node)
  }
  
}(window, document);